import React from "react"
import CustomImage from "../custom-image/custom-image.component"
import * as S from "./image-text.styles"

const ImageText = ({ title, subtitle, content, image, flipCols, className }) => {
  return (
    <S.CustomGrid container spacing={4} flipCols={flipCols} className={className}>
      <S.ImageContainer item xs={12} sm={12} md>
        {image && <CustomImage alt="" img={image} />}
      </S.ImageContainer>
      <S.ContentGrid item xs={12} sm={12} md>
        {title && <h2>{title}</h2>}
        {subtitle && <h3>{subtitle}</h3>}
        {content && <p>{content}</p>}
      </S.ContentGrid>
    </S.CustomGrid>
  )
}
export default ImageText
