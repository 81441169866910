import React from "react"
import * as S from "./image-form.styles.jsx"
import { Grid } from "@material-ui/core"
import parse from "html-react-parser"
import CustomImage from "../../components/custom-image/custom-image.component"
import ChallengeForm from "../../components/challenge-form/challenge-form.component"

const ImageForm = ({ image, title, content, disclaimer, showSocials, hideForm, classList, button }) => {
  return (
    <S.Wrapper className={classList}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={6}>
          {image && <CustomImage className="left-img" img={image} alt="jove" />}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          alignItems="center"
          container
          style={{ display: "flex" }}
        >
          <S.ContentGrid>
            {title && <h1>{title}</h1>}
            {content && <p>{parse(content)}</p>}
            <div className="actions">
            {showSocials && <S.SocialIcons className="blue" /> }
              <div className="cta">
                {button && <S.Button url={button.url} target={button.target}>{button.title}</S.Button>}
              </div>
            </div>
            {!hideForm && 
            <ChallengeForm />
            }
          </S.ContentGrid>
        </Grid>
      </Grid>
      {disclaimer && 
      <S.Disclaimer>
        {parse(disclaimer)}
      </S.Disclaimer>
      }
    </S.Wrapper>
  )
}
export default ImageForm
