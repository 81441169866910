import styled from "styled-components"
import Socials from "../../components/socials/socials.component"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import RoundedButton from "../../components/rounded-button/rounded-button.component"


export const Wrapper = styled(SectionWrapper)`
  background-color: white;
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 0;
  .left-img {
    height: 100%;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    .MuiGrid-item:first-child {
      order: 2;
    }
    .MuiGrid-item:last-child {
      padding-top: 2em;
      padding-bottom: 4em;
      order: 1;
    }
  }

  &.spa_vacation {
    padding-top: 0;

    h1 {
      font-size: 2.1rem;
      line-height: 1.2em;
    }

    & > .MuiGrid-container {
      padding-top: 0;
    }

    ${({ theme }) => theme.breakpoints.down("sm")} {
      .MuiGrid-item:last-child {
        padding-bottom: 0;
      }
    }

    .actions {
      margin-top: 2rem;
      margin-bottom: 2rem;
      display: flex;
      align-items: center;
    }
  }
`
export const ContentGrid = styled.div`
  max-width: 490px;
  padding-left: 6em;
  form {
    margin-top: 4em;
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 4em 2em;
    margin: auto;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin: auto;
    padding-left: 2em;
    padding-right: 2em;
  }
`

export const SocialIcons = styled(Socials)`
  margin-top: 0;
  flex-basis: 50%;
`

export const Button = styled(RoundedButton)`
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
`

export const Disclaimer = styled.div`
  background-color: #DBE9FB;
  padding: 5% 10%;
  text-align: center;

  p {
    font-family: ${({ theme }) => theme.fonts.tertiary};
    font-size: 1.1rem;
  }
`