import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  background: linear-gradient(
    to top,
    #7bb5f7 0%,
    #50f0cc 33%,
    #8ff3ab 100%
  );
  h1 {
    text-align: center;
    border-bottom: 6px solid #1e1499;
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;
  }

  .section {
    margin-bottom: 6em;
    gap: 3em;

    &:last-child { margin-bottom: 0; }

    h2 {
      font-size: 2.5em;
      margin-bottom: .5rem;
    }

    h3 { display: none; }
  }
`
