import styled from "styled-components"
import Grid from "@material-ui/core/Grid"

export const CustomGrid = styled(Grid)`
  margin-bottom: 3em;
  justify-content: center;
  align-items: center;
  ${({ flipCols }) =>
    flipCols
      ? `
      //justify-content:flex-end;
      .MuiGrid-item:last-child{
        order:1;
      }
      .MuiGrid-item:first-child{
        order:2;
      }
`
      : ``};

  .gatsby-image-wrapper {
    max-width: 350px;
    margin: auto;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    justify-content: center;
    .gatsby-image-wrapper {
      max-width: 250px;
    }
  }
`
export const ContentGrid = styled(Grid)`
  max-width: 600px;
  h2 {
    //max-width: 330px;
    margin-bottom: 1.5rem;
  }
  h3 {
    max-width: 400px;
  }
  p {
    letter-spacing: 0.15px;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    order: 2 !important;
    h2,
    h3,
    p {
      margin: auto;
      text-align: center;
    }
  }
`
export const ImageContainer = styled(Grid)`
  max-width: 350px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    order: 1 !important;
  }
`
