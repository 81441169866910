import React from "react"
import * as S from "./why-jove.styles.jsx"
import parse from "html-react-parser"
import ImageText from "../../components/image-text/image-text.component"

const WhyJove = ({ items, title }) => {
  if (!items) return null
  return (
    <S.Wrapper contained>
      { title && 
        <h1>{parse(title)}</h1>
      }
      {items.length && items.map((item, index) => (
        item.image && 
        <ImageText
          key={`item-${index}`}
          title={parse(item.title)}
          image={item.image}
          content={parse(item.content ?? "")}
          subtitle={parse(item.subtitle ?? "")}
          flipCols={index % 2 !== 0}
          className="section"
        />
      ))}
    </S.Wrapper>
  )
}
export default WhyJove
