import React from "react"
import * as S from "./reasons-grid.styles.jsx"
import { Grid } from "@material-ui/core"
import CustomImage from "../../components/custom-image/custom-image.component"

const ReasonsGrid = ({ items, title, classList }) => {
  if (!items) return null
  return (
    <S.Wrapper contained className={classList}>
      <Grid container justifyContent="center" alignItems="flex-end" spacing={7}>
        <S.TitleGrid item sm={12} md={12} lg={4}>
          <h3>{title}</h3>
        </S.TitleGrid>
        {items.map((item, index) => (
          <Grid item xs={12} sm md key={`items-${index}`}>
            <S.CustomContainer>
              {item.image && <CustomImage alt="" img={item.image} />}
              {item.title && <h4>{item.title}</h4>}
            </S.CustomContainer>
          </Grid>
        ))}
      </Grid>
    </S.Wrapper>
  )
}
export default ReasonsGrid
