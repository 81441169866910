import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import Grid from "@material-ui/core/Grid"

export const Wrapper = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: 4em 0;
  color: white;

  h4 {
    border-bottom: 4px solid white;
    padding-bottom: 10px;
  }
  .gatsby-image-wrapper {
    max-width: 170px;
    max-height: 150px;
    margin: 0 auto 1em;
    img {
      object-fit: contain;
    }
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    h3 {
      text-align: center;
      margin: auto;
    }
  }
`
export const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  div {
    width: 100%;
    height: 100%;
    text-align: center;
  }
  h4 {
    margin-top: auto;
    text-align: center;
  }
  ${({ theme }) => theme.breakpoints.down("xs")} {
    flex-direction: row;
    padding: 0 2em;
    .gatsby-image-wrapper {
      min-width: 123px;
    }
    h4 {
      padding-right: 0;
      margin: auto;
      width: 100%;
    }
  }
`
export const TitleGrid = styled(Grid)`
  display: flex;
  align-items: center;
`
